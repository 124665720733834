<template>
  <div :class="[level, 'row-edition']">
    <div class="cell left">
      <div class="title">{{ $t(`rowEdition.${overview}`) }}</div>
      <div v-if="overview !== 'forecastSalesQuantity'" class="sub-title">{{ $t('rowEdition.inArticlesNb') }}</div>
    </div>
    <div v-for="(day, i) in days" :key="`days-${i}`" class="cell">
      <div class="coef">{{ $filters.formatNumber(day[overview] === null ? overview === 'evolutionPercentSalesQuantity' ? 100 : 1 : day[overview], overview === 'forecastSalesQuantity' ? 0 : 2, overview === 'evolutionPercentSalesQuantity' ? '%' : '', overview === 'evolutionPercentSalesQuantity') }}</div>
      <div v-if="overview !== 'forecastSalesQuantity'" class="articles">
        {{ day.forecastSalesQuantity === null ? 'NC' : $filters.formatNumber(day.forecastSalesQuantity, 0) }}
      </div>
    </div>
    <div class="blank" />
    <div v-if="!category.isPadlock" class="cell left">
      <div class="title">{{ $t(`rowEdition.maj.${overview}`) }}</div>
      <div v-if="overview !== 'forecastSalesQuantity'" class="sub-title">{{ $t('rowEdition.inArticlesNb') }}</div>
    </div>

    <template v-if="!category.isPadlock">
      <div v-for="(forecast, i) in newForecasts" :key="`newDays-${i}`">
        <div v-if="overview !== 'forecastSalesQuantity'" class="cell">
          <div>
            <input v-model="newForecasts[i]"
                   :disabled="disabledInput(days[i])"
                   :class="[{'alert-error': errors[i]},'input-coef']"
                   type="number"
                   step=".1"
                   min="0"
                   max="5">
            <span v-if="overview === 'evolutionPercentSalesQuantity'">%</span>
          </div>
          <div class="sep" />
          <div v-if="overview === 'coefficient'" class="articles">{{ forecast ? $filters.formatNumber(days[i].averageSalesQuantity * forecast, 0) : '' }}</div>
          <div v-else class="articles">{{ forecast ? $filters.formatNumber(days[i].averageSalesQuantity * ((forecast / 100) / 100+1), 0) : '' }}</div>
        </div>
        <div v-else class="cell articles">{{ forecast ? $filters.formatNumber(days[i]?.forecastSalesQuantityUser, 0) : '-' }} </div>
      </div>
    </template>
    <div v-if="!category.isPadlock && overview !== 'forecastSalesQuantity'" class="cell validate">
      <v-svg v-if="!loading" h="0.6rem" name="cross" @click="cancel" />
      <v-svg v-if="!loading" h="0.7rem" name="validate" @click="validate" />
      <v-svg v-else h="1rem" name="spinner" />
    </div>
    <div v-if="!family && overview !== 'forecastSalesQuantity'" class="cell left">
      <div class="title">{{ $t('rowEdition.inputReamReas') }}</div>
      <div class="sub-title">{{ $t('rowEdition.totalArticles') }}</div>
    </div>
    <template v-if="!family && overview !== 'forecastSalesQuantity'">
      <div v-for="(day, i) in days" :key="`ream-days-${i}`" class="cell">
        <div v-if="day.forecastType !== -1" :class="[buildColorClassIndicator(day),'ream-forecast']">{{ day.forecastType === 1 ? 'x ' : day.forecastType === 3 ? '[' : '' }}{{ day.lowValue }}{{ day.forecastType === 3 ? ` - ${day.highValue}]` : '' }}</div>
        <div v-else class="none"> - </div>
        <div class="articles"> {{ $filters.formatNumber(buildArticlesTotal(day, newForecasts[i]), 0) }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { inject, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'
import { DateTime } from 'luxon'
import { extendWithAuthorization, http } from '@/http'
import { buildColorClassIndicator } from '@/utils/indicators.utils'

export default {
  name: 'RowEdition',
  props: {
    level: {
      type: String,
      required: true
    },
    days: {
      type: Object,
      required: true
    },
    overview: {
      type: String,
      required: true
    },
    category: {
      type: Object,
      required: true
    },
    family: {
      type: Object,
      false: true
    },
    geographyId: {
      type: String,
      required: true
    }
  },
  emits: ['fetchItemRow'],
  setup(props, {emit}) {
    const toast = useToast()

    const newForecasts = ref(props.days.reduce((forecast, day) => [...forecast, day[`${props.overview}User`] ?? null], []))
    const errors = ref(Array(7).fill(false))
    const loading = ref(false)
    const userInfos = inject('userInfos')

    const now = DateTime.now()

    const disabledInput = forecastItem => {
      return forecastItem.forecastType === 2 || (now.toISODate() === forecastItem.day && now.hour >= '14') || forecastItem.day < now.toISODate()
    }

    watch(() => (props.overview), (overview, prevOverview) => {
      if(overview !== 'forecastSalesQuantity' && prevOverview !== 'forecastSalesQuantity') {
        let newTableInput = props.days.reduce((forecast, day) => [...forecast, day[`${props.overview}User`] ?? null], [])
        const initialForecast = props.days.reduce((forecast, day) => [...forecast, day[`${prevOverview}User`] ?? null], [])
        initialForecast.forEach((item, index) => {
          if(item !== newForecasts.value[index]) {
            newTableInput[index] = overview === 'coefficient' && prevOverview === 'evolutionPercentSalesQuantity' ? newForecasts.value[index] /100 + 1 : (newForecasts.value[index] -1)* 100
          }
        })
        newForecasts.value = newTableInput
      }
    })

    const emitter = inject("emitter")

    //TODO: ESLint: Unexpected mutation of "days" prop.(vue/no-mutating-props) Remonter la donnée et faire l'assignement dans le composant parent ?
    const validate = async() => {
      loading.value = true

      const daysCopy = JSON.parse(JSON.stringify(props.days))

      newForecasts.value.forEach((forecast,i) => {
        if(daysCopy[i][[`${props.overview}User`]] !== forecast) {
          //Je suis dans le cas d'un bornage REAM
          if(daysCopy[i].forecastType === 3) {
            const highValue = props.overview === 'coefficient' ? daysCopy[i].highValue : (daysCopy[i].highValue -1)*100
            const lowValue = props.overview === 'coefficient' ? daysCopy[i].lowValue : (daysCopy[i].lowValue -1)*100
              errors[i] = forecast > highValue || forecast < lowValue
          }
          else {
            const highValue = props.overview === 'coefficient' ? 5 : 400
            const lowValue = props.overview === 'coefficient' ? 0 : -100
              errors.value[i] = forecast ? (forecast > highValue || forecast < lowValue) : false
          }

          daysCopy[i][`${props.overview}User`] = forecast
          daysCopy[i].isNewValue = true
          daysCopy[i].isManualValue = forecast !== ''
        }
      })

      if (errors.value.find(error => error === true) !== undefined) {
        toast.error("Validation impossible, il y a une ou plusieurs erreurs dans votre saisie")
        loading.value = false
      } else {
        const updatedDays = daysCopy.filter(day => day.isNewValue)

        const isVirtual = props.family ? props.family.isVirtual : props.category.isVirtual

        const extendedHttp = await extendWithAuthorization(http)

        const callName =`indicators/forecasts/sites/${props.geographyId}/categories/${props.family ? `${props.family.category}/families/${props.family.family}` : `${props.category.category}`}`

        await extendedHttp
          .post(callName, { json: { days: updatedDays, userCode: userInfos?.uid, overview: props.overview, isVirtual }})
          .json()
          .then(() => {
            if (isVirtual) emitter.emit('reloadData')
            emit('fetchItemRow')
            toast.success("Le coefficient a été ajouté avec succès")
          })
          .catch(err => {
            toast.error("Une erreur est survenue lors de l'ajout de vos coefficients")
            console.error(err)
          })
        loading.value = false
      }
    }

    const cancel = () => {
      newForecasts.value = props.days.reduce((forecast, day) => [...forecast, day[`${props.overview}User`] ?? null], [])
    }

    const buildArticlesTotal = (forecastRow, newValue) => {
      let coef = newValue ? newValue : forecastRow[props.overview]
      if(props.overview !== 'coefficient'){
        coef = 1 + (coef/100)
      }
      let coefFinal
      if(forecastRow.forecastType === 1) {
        coefFinal = forecastRow.lowValue * coef > 5 ? 5 : forecastRow.lowValue * coef
      } else if(forecastRow.forecastType === 3) {
        coefFinal = coef > forecastRow.lowValue ? coef < forecastRow.highValue ? coef : forecastRow.highValue : forecastRow.lowValue
      } else if(forecastRow.forecastType === 2) {
        coefFinal = forecastRow.lowValue
      }
      return coefFinal * forecastRow.averageSalesQuantity
    }

    return { newForecasts, validate, cancel, disabledInput, buildColorClassIndicator, errors, loading, buildArticlesTotal }
  }
}
</script>

<style scoped lang="scss">
.row-edition {
  display: grid;
  row-gap: 2rem;
  padding: 1rem 0;
  margin-top: -1rem;
  border: 1px solid #CFCFCF;
  border-radius: 7px 7px 7px 7px;
  grid-template-columns: 40% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  &.child {
    margin: -.6rem 0 0 40px;
    grid-template-columns: calc(40% - 1.5rem) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &.grand-child {
    margin: -1rem 0 0 80px;
    grid-template-columns: calc(40% - 3rem) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @extend %secondary-font;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.left {
      align-items: flex-start;
      padding-left: 2rem;
    }

    .sub-title, .articles {
      color: #A6A6A6;
    }

    .sep {
      margin: 0.1rem 0;
      width: 60%;
      height: 1px;
      background-color: #808080;
    }

    .articles {
      min-height: 20px;
    }

    &.validate {
      flex-direction: row;

      .loader {

      }
    }

    .svg-cross { margin-right: 0.3rem; }

    .svg-validate, .svg-lock, .svg-cross { cursor: pointer; }

    .input-coef {
      width: 40px;
      height: 20px;
      border: none;
      text-align: center;

      &:focus{
        outline: none;
      }

      &.alert-error {
        border-style: solid;
        border-color: red;
      }

      &:disabled {
        background-color: #dfdcdc;
      }
    }

    .ream-forecast {
      font-weight: bold;
      &.multiplier { color: var(--color-multiplier); }

      &.limited { color: var(--color-limited); }

      &.forced { color: var(--color-forced); }
    }
  }
}
</style>
